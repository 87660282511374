
export const getStoredLanguage = () => {

    if (typeof window === 'undefined' && typeof localStorage === 'undefined') {
        // check if server-side or client browser
        return 'en';
    }
    
    const storedLanguage = localStorage.getItem('userPreferredLanguage');
    // TODO: add checks for valid language?
    if (storedLanguage && storedLanguage.length === 2) {
        // assume it is a language code
        return storedLanguage
    } else {
        return undefined;
    };
};

export const getAssetUrlLanguageTrail = () => {
    const languageCode = getStoredLanguage();
    if (languageCode && languageCode !== "en") {
        return `_${languageCode}`;
    } else {
        return '';
    }
}